import React from "react";
import HomePage from "../../components/layout/Home";
import SearchModal from "../../components/home/search-modal";
// import BetaAlertTakeover from '../../components/common/BetaAlertTakeover';

export default props => {
  return (
    <HomePage>
      <SearchModal backlink="/" />
      {/* <BetaAlertTakeover /> */}
    </HomePage>
  );
};
